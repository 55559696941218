"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAllMentions = exports.isBullet = exports.isNodeNYCTRoute = exports.isMention = void 0;
const core_1 = require("@remirror/core");
const isMention = (nodeWithPos) => {
    return (nodeWithPos.node.marks &&
        nodeWithPos.node.marks.length === 1 &&
        nodeWithPos.node.marks[0].type.name === 'mention' &&
        !!nodeWithPos.node.marks[0].attrs['data-bullet-type']);
};
exports.isMention = isMention;
const isNodeNYCTRoute = (nodeWithPos) => {
    return (nodeWithPos &&
        (0, exports.isMention)(nodeWithPos) &&
        nodeWithPos.node.marks[0].attrs['data-bullet-id']?.startsWith('MTA'));
};
exports.isNodeNYCTRoute = isNodeNYCTRoute;
const isBullet = (nodeWithPos) => {
    const blockType = nodeWithPos.node.type.name;
    return blockType === 'bulletList' || blockType === 'listItem';
};
exports.isBullet = isBullet;
const getAllMentions = ({ doc }) => {
    const mentionNodes = (0, core_1.findTextNodes)({ node: doc }).filter(exports.isMention);
    return mentionNodes;
};
exports.getAllMentions = getAllMentions;
