import { createGlobalStyle } from 'styled-components';

import pxToViewport from './utils/px-to-viewport-units';

const helveticaBold = require('../fonts/Helvetica-Bold.ttf');
const helveticaLight = require('../fonts/Helvetica-Light.ttf');
const helvetica = require('../fonts/Helvetica.ttf');

// All bullet icons
const icons: { [s: string]: string } = {
  'mtasbwy-1': require('../images/bullets/1.svg'),
  'mtasbwy-2': require('../images/bullets/2.svg'),
  'mtasbwy-3': require('../images/bullets/3.svg'),
  'mtasbwy-4': require('../images/bullets/4.svg'),
  'mtasbwy-5': require('../images/bullets/5.svg'),
  'mtasbwy-6': require('../images/bullets/6.svg'),
  'mtasbwy-6x': require('../images/bullets/6d.svg'),
  'mtasbwy-7': require('../images/bullets/7.svg'),
  'mtasbwy-7x': require('../images/bullets/7d.svg'),
  'mtasbwy-a': require('../images/bullets/a.svg'),
  'mtasbwy-b': require('../images/bullets/b.svg'),
  'mtasbwy-c': require('../images/bullets/c.svg'),
  'mtasbwy-d': require('../images/bullets/d.svg'),
  'mtasbwy-e': require('../images/bullets/e.svg'),
  'mtasbwy-f': require('../images/bullets/f.svg'),
  'mtasbwy-fx': require('../images/bullets/fx.svg'),
  'mtasbwy-fs': require('../images/bullets/fs.svg'),
  'mtasbwy-g': require('../images/bullets/g.svg'),
  'mtasbwy-gs': require('../images/bullets/s.svg'),
  'mtasbwy-h': require('../images/bullets/h.svg'),
  'mtasbwy-j': require('../images/bullets/j.svg'),
  'mtasbwy-l': require('../images/bullets/l.svg'),
  'mtasbwy-m': require('../images/bullets/m.svg'),
  'mtasbwy-n': require('../images/bullets/n.svg'),
  'mtasbwy-q': require('../images/bullets/q.svg'),
  'mtasbwy-r': require('../images/bullets/r.svg'),
  'mtasbwy-s': require('../images/bullets/s.svg'),
  'mtasbwy-si': require('../images/bullets/sir.svg'),
  'mtasbwy-t': require('../images/bullets/t.svg'),
  'mtasbwy-w': require('../images/bullets/w.svg'),
  'mtasbwy-z': require('../images/bullets/z.svg'),
};

// Custom icons
const customIcons: { [s: string]: string } = {
  ada: require('../images/icon_ada.svg'),
  shuttle_bus: require('../images/icon_bus.svg'),
  airplane: require('../images/icon_airplane.svg'),
};

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Helvetica;
    src: url(${helveticaBold}) format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: Helvetica;
    src: url(${helveticaLight}) format('truetype');
    font-weight: lighter;
  }

  @font-face {
    font-family: Helvetica;
    src: url(${helvetica}) format('truetype');
    font-weight: normal;
  }

  @media (orientation: portrait) {
    html {
      font-size: 1vw;
    }

    [data-bullet-route-id^='mtasbwy-'] {
      width: ${pxToViewport(130)}rem;
    }

    [data-bullet-display^='ada'],
    [data-bullet-display^='shuttle_bus'],
    [data-bullet-display^='airplane'] {
      width: ${pxToViewport(130)}rem;
    }
  }

  @media (orientation: landscape) {
    html {
      font-size: 1vh;
    }

    [data-bullet-route-id^='mtasbwy-'] {
      width: ${pxToViewport(115)}rem;
    }

    [data-bullet-display^='ada'],
    [data-bullet-display^='shuttle_bus'],
    [data-bullet-display^='airplane'] {
      width: ${pxToViewport(115)}rem;
    }
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  body {
    font-family: Helvetica, sans-serif;
    line-height: 1.33;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  [data-bullet-route-id^='mta-'],
    display: inline-block;
    font-size: 72%;
    font-weight: bold;
    text-transform: uppercase;
    padding: 2.5rem 3rem 1rem;
    vertical-align: top;
    line-height: 1;
    border-radius: 1rem;
  }

  [data-bullet-agency^='nyct-'] {
    font-weight: 700;
  }

  [data-bullet-agency='nyct-bus'] {
    background-color: transparent !important;
  }

  [data-bullet-route-id^='mtasbwy-'] {
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    text-indent: -9999em;
    font-size: 0.01em;
  }

  [data-bullet-display^='ada'],
  [data-bullet-display^='shuttle_bus'],
  [data-bullet-display^='airplane'] {
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    text-indent: -9999em;
    font-size: 0.01em;
  }

  [data-bullet-route-id^='li'],
  [data-bullet-route-id^='mnr'],
  [data-bullet-route-id^='njt'] {
    display: inline;
    font-weight: bold;

    /* Override bullet style attributes in HTML */
    color: black !important;
    background-color: inherit !important;
  }

  [data-bullet-type='STOP'] {
    display: inline;
    font-weight: bold;
  }

  [data-bullet-type='TRIP'] {
    display: inline;
    font-weight: bold;
  }

  ${Object.keys(icons).map(
    (key) => `
    [data-bullet-route-id='${key}'] {
      background-image: url('${icons[key]}');
    }
  `,
  )}

  ${Object.keys(customIcons).map(
    (key) => `
    [data-bullet-display^='${key}'] {
      background-image: url('${customIcons[key]}');
    }
  `,
  )}
`;

export default GlobalStyles;
